<template>
  <label class="checkbox" data-t="st-checkbox">
    <input v-model="model" class="input" type="checkbox" :disabled="disabled" />
    <span class="checkmark" :class="{ size }" />
    <span class="st-label"><slot /></span>
  </label>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    disabled?: boolean
    size?: 's' | 'm'
    modelValue: boolean
    accentColor?: string
  }>(),
  {
    size: 'm',
    accentColor: '#FE5E01',
  },
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const sizes = computed(() => (props.size === 's' ? '16px' : '20px'))
</script>

<style scoped>
.checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
  min-width: v-bind(sizes);
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;

  width: v-bind(sizes);
  height: v-bind(sizes);

  background: var(--background-base);
  border: var(--border-width-bold) solid rgb(255 255 255 / 24%);
  border-radius: var(--border-radius-075);

  transition: 0.2s ease-in-out border-color;

  &.s {
    border-radius: var(--border-radius-050);
  }
}

.input {
  cursor: pointer;

  position: absolute;
  left: 0;

  width: 0;
  height: 0;

  opacity: 0;
}

.input:checked + .checkmark {
  background-color: v-bind(accentColor);
  background-image: url('./checkmark.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  border: none;
}

.input:focus-visible + .checkmark {
  border: var(--border-width-bold, 1.5px) solid v-bind(accentColor);
  box-shadow: var(--focus-rings-button-primary);
}

.input:disabled + .checkmark {
  cursor: not-allowed;
  opacity: 0.4;
  background-color: var(--background-ghost-tertiary);
  border: var(--border-width-bold) solid rgb(255 255 255 / 24%);
}

.input:not(:checked, :disabled) + .checkmark:hover {
  border-color: v-bind(accentColor);
}

.input:checked + .checkmark:hover {
  box-shadow: var(--focus-rings-button-primary);
}

.input:disabled:checked + .checkmark {
  cursor: not-allowed;
  background-color: v-bind(accentColor);
  border: none;
}

.st-label {
  margin-left: calc(v-bind(sizes) + 8px);
}
</style>
